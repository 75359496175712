import type { FeeValue, FinancialInstrument, MarketType, IssuanceType, FeeType, BasicIssuanceFees, IssuanceFees, MarketTypeFees } from '../types'

export const EXTRA_COORDINATION_RATE = 250
export const EXTRA_COORDINATION_PORTFOLIO_HOURS = 80
export const EXTRA_COORDINATION_TRADITIONAL_HOURS = 160
export const SAVING_PERIOD = 10

/**
 * Get the Agent Bank fee
 * Fee paid to an agent bank for managing dividend and coupon payments.
 *
 * - In Portfolio, there is no associated cost (not required)
 * - In traditional markets, the fee is 20,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 */
function getAgentBank(isPortfolio: boolean): FeeValue {
  if (isPortfolio) return null
  return 20_000
}

/**
 * Get the Application/Pre-admission fee
 * Fee associated with the listing process of a new issuance
 *
 * - There is no maintenance fee (not visible)
 * - In Portfolio, the fee is 5,000€.
 * - In traditional markets, the fee is 0€ for migrations and 6,000€ for the rest.
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isMigration - The issuance type, either new or migration
 * @param isMaintenanceFee - The fee type, either initial or maintenance fee
 */
function getApplication(isPortfolio: boolean, isMigration: boolean, isMaintenanceFee: boolean): FeeValue {
  if (isMaintenanceFee) return undefined
  if (isPortfolio) return 5000
  if (isMigration) return 0
  return 6000
}

/**
 * Get the Asset Valuation fee
 * Fee for valuing the assets involved in the issuance, whether real estate or the company itself.
 *
 * - If the financial instrument is equity, the fee is 'toBeDefined'
 * - In Portfolio,
 *   - There is no associated cost for initial fees (not required)
 *   - The maintenance fee is 8,000€
 * - In traditional markets, the initial fee is 25,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isMaintenanceFee - The fee type, either initial or maintenance fee
 * @param financialInstrument - The type of financial instrument
 */
function getAssetValuation(isPortfolio: boolean, isMaintenanceFee: boolean, isEquity: boolean): FeeValue {
  if (isEquity) return 'toBeDefined'
  if (isPortfolio) {
    if (isMaintenanceFee) return 8000
    return null
  }
  return 25_000
}

/**
 * Get the Audit Firm fee
 * Fee for auditing services (financial statements) of a company, carried out by an external audit firm.
 *
 * - If the issuance is new, the initial fee is 20,000€
 * - For all other cases, the fee is €10,000
 *
 * @param isNew - The issuance type, either new or migration
 * @param isInitialFee - The fee type, either initial fee maintenance fee
 */
function getAutidFirm(isNew: boolean, isInitialFee: boolean): FeeValue {
  if (isNew && isInitialFee) return 20_000
  return 10_000
}

/**
 * Get the Semi-Annual Audit Firm fee
 * Fee for semi-annual audit reviews, conducted mid-year to ensure financial transparency and accuracy
 *
 * - If the issuance is new, there is no initial fee (not visible)
 * - In Portfolio, there is no associated cost (not required)
 * - In traditional markets, the fee is 5,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isNew - The issuance type, either new or migration
 * @param isInitialFee - The fee type, either initial fee maintenance fee
 */
function getAuditFirmSemi(isPortfolio: boolean, isNew: boolean, isInitialFee: boolean): FeeValue {
  if (isNew && isInitialFee) return undefined
  if (isPortfolio) return null
  return 5000
}

/**
 * Get the Comfort Letter fee
 * Fee for an official document issued by lawyers that validates the provided documentation and its truthfulness
 *
 * - There is no maintenance fee (not visible)
 * - There is no cost for migrations (not visible)
 * - In Portfolio, there is no associated cost (not required)
 * - In traditional markets, the fee is €8,000
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isMaintenanceFee - The fee type, either initial fee maintenance fee
 * @param isMigration - The issuance type, either new or migration
 */
function getComfortLetter(isPortfolio: boolean, isMaintenanceFee: boolean, isMigration: boolean): FeeValue {
  if (isMaintenanceFee) return undefined
  if (isMigration) return undefined
  if (isPortfolio) return null
  return 8000
}

/**
 * Get the CSD fee
 * Fee for deposit and maintenance services of securities in a central depository.
 *
 * - For amounts up to 400,000,000€, the fee is 0.007% of the value
 * - For higher amounts, the applied fee is 0.0042% of the value
 * - In Portfolio, add an additional fee of 8,000€ to the initial fee
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isInitialFee - The fee type, either initial fee maintenance fee
 * @param amount - The capitalization amount of the issuance
 */
function getCSD(isPortfolio: boolean, isInitialFee: boolean, amount: number): FeeValue {
  if (!amount) return 0

  const baseRate = amount <= 400_000_000 ? 0.00007 : 0.000042
  const extraRate = isPortfolio && isInitialFee ? 8000 : 0
  return amount * baseRate + extraRate
}

/**
 * Get the Custody fee
 * Fee for the custody of the issued securities
 *
 * - In Portfolio, the fee is 20,000€
 * - In traditional markets, the fee is calculated as 0.05% of the value, with a maximum of 50,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param amount - The capitalization amount of the issuance
 */
function getCustody(isPortfolio: boolean, amount: number): FeeValue {
  if (isPortfolio) return 20_000
  if (!amount) return 0

  return Math.min(50_000, amount * 0.0005)
}

/**
 * Get the Extra Coordination fee
 * Fee for additional coordination and management services necessary to carry out the issuance.
 *
 * - There is no maintenance fee (not visible)
 * - In Portfolio, there is no cost for new emissions (not required)
 * - In traditional markets, the fee is 0€ for migrations
 * - In other cases, the fee is calculated by multiplying the hours by the price per hour
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isMaintenanceFee - The fee type, either initial fee maintenance fee
 * @param isMigration - The issuance type, either new or migration
 * @param isNew - The issuance type, either new or migration
 */
function getExtraCoordination(isPortfolio: boolean, isMaintenanceFee: boolean, isMigration: boolean, isNew: boolean): FeeValue {
  const rate = EXTRA_COORDINATION_RATE
  const hours = isPortfolio ? EXTRA_COORDINATION_PORTFOLIO_HOURS : EXTRA_COORDINATION_TRADITIONAL_HOURS

  if (isMaintenanceFee) return undefined
  if (isPortfolio && isNew) return null
  if (!isPortfolio && isMigration) return 0
  return hours * rate
}

/**
 * Get the Financial Due Diligence fee
 * Fee for the detailed financial review and analysis conducted by third parties.
 *
 * - There is no maintenance fee (not visible)
 * - If the financial instrument is "Equity", the fee is 'toBeDefined'
 * - There is no cost for migrations (not visible)
 * - In Portfolio, there is no associated cost (not required)
 * - In traditional markets, the fee is 20,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isMaintenanceFee - The fee type, either initial fee maintenance fee
 * @param isNew - The issuance type, either new or migration
 */
function getFinancialDueDiligence(isPortfolio: boolean, isMaintenanceFee: boolean, isMigration: boolean, isEquity: boolean): FeeValue {
  if (isMaintenanceFee) return undefined
  if (isEquity) return 'toBeDefined'
  if (isMigration) return undefined
  if (isPortfolio) return null
  return 20_000
}

/**
 * Get the Law Firm fee
 * Fee for legal services provided by a law firm
 *
 * - The fee is 'toBeDefined' in all cases
 */
function getLawFirm(): FeeValue {
  return 'toBeDefined'
}

/**
 * Get the Legal Due Diligence fee
 * Fee for the detailed legal review to ensure that all documents and procedures are correct before the offering.
 *
 * - There is no maintenance fee (not visible)
 * - If the financial instrument is equity, the fee is 'toBeDefined'
 * - There is no cost for migrations (not visible)
 * - In Portfolio, there is no associated cost (not required)
 * - In traditional markets, the fee is 20,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isMaintenanceFee - The fee type, either initial fee maintenance fee
 * @param isMigration - The issuance type, either new or migration
 */
function getLegalDueDiligence(isPortfolio: boolean, isMaintenanceFee: boolean, isMigration: boolean, isEquity: boolean): FeeValue {
  if (isMaintenanceFee) return undefined
  if (isEquity) return 'toBeDefined'
  if (isMigration) return undefined
  if (isPortfolio) return null
  return 20_000
}

/**
 * Get the Liquidity Provider fee
 * Fee for the services of an entity that helps ensure there are always enough buyers and sellers for the offered shares or bonds
 *
 * - In Portfolio, there is no associated cost (not required)
 * - In traditional markets, the fee is 30,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 */
function getLiquidityProvider(isPortfolio: boolean): FeeValue {
  if (isPortfolio) return null
  return 30_000
}

/**
 * Get the Listing fee
 * Fee associated with the initial listing process of the issuance in the market
 *
 * - There is no maintenance fee (not visible)
 * - The cost for migrations is 0€
 *
 * EQUITY
 * - The cost is 0.125% of the value, with a maximum limit of 300,000€
 *
 * REIT
 * - If the value of the issuance is up to 120,000,000€, the cost is 0.125% of the value
 * - If the value of the issuance is between 20,000,000€ and 300,000,000€, the cost is 155,000€
 * - If the value of the issuance exceeds 300,000,000€, the cost is 200,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isMaintenanceFee - The fee type, either initial fee maintenance fee
 * @param isNew - The issuance type, either new or migration
 */
function getListing(isMaintenanceFee: boolean, isMigration: boolean, amount: number, financialInstrument: FinancialInstrument): FeeValue {
  if (isMaintenanceFee) return undefined
  if (isMigration) return 0
  if (!amount) return 0

  switch (financialInstrument) {
    case 'equity':
      return Math.min(300_000, amount * 0.00125);
    case 'reit':
      if (amount <= 120_000_000) {
        return amount * 0.00125;
      } else if (amount <= 300_000_000) {
        return 155_000;
      } else {
        return 200_000;
      }
    default:
      return 0;
  }
}

/**
 * Get the Listing Sponsor fee
 * Fee for managing the process of going public and complying with all regulations with the market in the subsequent years,
 * generally carried out by a specialized entity
 *
 * - In Portfolio, there is no associated cost (not required)
 * - In traditional markets
 *   - If the issuance is new, the initial fee is is 50,000€
 *   - For all other cases, the fee is 30,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isInitialFee - The fee type, either initial fee maintenance fee
 * @param isNew - The issuance type, either new or migration
 */
function getListingSponsor(isPortfolio: boolean, isInitialFee: boolean, isNew: boolean): FeeValue {
  if (isPortfolio) return null
  if (isNew && isInitialFee) return 50_000
  return 30_000
}

/**
 * Get the Maintenance fee
 * Annual fee to keep the offer active in the market, ensuring it continues to meet the required standards
 *
 * - If the issuance is new, there is no initial fee
 * - In Portfolio, the cost for the initial fee is 0€, for the rest of the years, the cost is 5,000€
 * - In traditional markets, the fee is 3,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isMigration - The issuance type, either new or migration
 * @param isMaintenanceFee - The fee type, either initial fee maintenance fee
 */
function getMaintenance(isPortfolio: boolean, isInitialFee: boolean, isNew: boolean): FeeValue {
  if (isNew && isInitialFee) return undefined
  if (isPortfolio) {
    if (isInitialFee) return 0
    return 5000
  }
  return 3000
}

/**
 * Get the Paying Agent fee
 * Fee for the services of a payment agent in charge of distributing dividend or interest payments to investors
 *
 * - In Portfolio, there is no associated cost (not required)
 * - In traditional markets, the fee is 5,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 */
function getPayingAgent(isPortfolio: boolean): FeeValue {
  if (isPortfolio) return null
  return 5000
}

/**
 * Get the Website fee
 * Cost for the development and maintenance of the website dedicated to providing information and details about the offering to investors
 *
 * - In Portfolio, there is no associated cost (not required)
 * - In traditional markets:
 *   - If the issuance is new, the initial fee is 2,500€
 *   - For all other cases, the fee is 1,000€
 *
 * @param isPortfolio - The market type, either portfolio or traditional
 * @param isInitialFee - The fee type, either initial fee maintenance fee
 * @param isNew - The issuance type, either new or migration
 */
function getWebsite(isPortfolio: boolean, isInitialFee: boolean, isNew: boolean): FeeValue {
  if (isPortfolio) return null
  if (isNew && isInitialFee) return 2500
  return 1000
}

/**
 * Returns the applicable fees for an issuance
 *
 * @param issuanceType - Specifies if the issuance is new or a migration
 * @param feeType - Specifies if the fees are for listing or maintenance
 * @param marketType - Specifies the market type, either portfolio or traditional
 * @param amount - The capitalization amount of the issuance
 * @param financialInstrument - The type of financial instrument
 */
function getIssuanceFees(financialInstrument: FinancialInstrument, issuanceType: IssuanceType, amount: number, feeType: FeeType, marketType: MarketType) {
  const isPortfolio = marketType === 'portfolio'
  const isTraditional = marketType === 'traditional'
  const isNew = issuanceType === 'new'
  const isMigration = issuanceType === 'migration'
  const isInitialFee = feeType === 'initialFee'
  const isMaintenanceFee = feeType === 'maintenanceFee'
  const isEquity = financialInstrument === 'equity'
  const isReit = financialInstrument === 'reit'

  const fees: BasicIssuanceFees = {
    application: getApplication(isPortfolio, isMigration, isMaintenanceFee),
    maintenance: getMaintenance(isPortfolio, isInitialFee, isNew),
    listing: getListing(isMaintenanceFee, isMigration, amount, financialInstrument),
    custody: getCustody(isPortfolio, amount),
    csd: getCSD(isPortfolio, isInitialFee, amount),
    listingSponsor: getListingSponsor(isPortfolio, isInitialFee, isNew),
    agentBank: getAgentBank(isPortfolio),
    payingAgent: getPayingAgent(isPortfolio),
    liquidityProvider: getLiquidityProvider(isPortfolio),
    website: getWebsite(isPortfolio, isInitialFee, isNew),
    extraCoordination: getExtraCoordination(isPortfolio, isMaintenanceFee, isMigration, isNew),
    auditFirm: getAutidFirm(isNew, isInitialFee),
    auditFirmSemi: getAuditFirmSemi(isPortfolio, isNew, isInitialFee),
    assetValuation: getAssetValuation(isPortfolio, isMaintenanceFee, isEquity),
    legalDueDiligence: getLegalDueDiligence(isPortfolio, isMaintenanceFee, isMigration, isEquity),
    financialDueDiligence: getFinancialDueDiligence(isPortfolio, isMaintenanceFee, isMigration, isEquity),
    lawFirm: getLawFirm(),
    comfortLetter: getComfortLetter(isPortfolio, isMaintenanceFee, isMigration),
  }

  return fees
}

/**
 * Returns the total issuance fees
 *
 * @param fees - The issuance fees
 */
function useTotalIssuanceFee(fees: BasicIssuanceFees) {
  return Object
    .values(fees)
    .reduce((acc, fee) => {
      if (fee === 'toBeDefined' || !fee) return acc
      return acc + fee
    }, 0)
}

/**
 * Calculates the savings over a specified period of time by comparing the total cost
 * between a traditional stock market and a Portfolio stock market.
 *
 * @param traditionalFirstYear - The listing fee for the traditional market.
 * @param traditionalMaintenance - The annual maintenance fee for the traditional market.
 * @param portfolioFirstYear - The listing fee for the Portfolio market.
 * @param portfolioMaintenance - The annual maintenance fee for the Portfolio market.
 */
function useGetSavingMoney(traditionalFirstYear: number, traditionalMaintenance: number, portfolioFirstYear: number, portfolioMaintenance: number) {
  const traditionalTotal = traditionalFirstYear + (traditionalMaintenance * (SAVING_PERIOD - 1))
  const portfolioTotal = portfolioFirstYear + (portfolioMaintenance * (SAVING_PERIOD - 1))

  return traditionalTotal - portfolioTotal
}

function getSavingPercent(traditionalFirstYear: number, traditionalMaintenance: number, portfolioFirstYear: number, portfolioMaintenance: number) {
  const traditionalTotal = traditionalFirstYear + (traditionalMaintenance * (SAVING_PERIOD - 1))
  const portfolioTotal = portfolioFirstYear + (portfolioMaintenance * (SAVING_PERIOD - 1))

  // Calculate the percentage of savings and fix it to two decimal places as a number
  return Math.round(((traditionalTotal - portfolioTotal) / traditionalTotal) * 100)
}

/**
 * Groups the fees by category and market type
 *
 * @param traditionalFees - All the fees for the traditional market
 * @param portfolioFees - All the fees for the portfolio market
 * @returns
 */
function groupFees(traditionalFees: BasicIssuanceFees, portfolioFees: BasicIssuanceFees): MarketTypeFees {
  return Object
    .keys(traditionalFees)
    .reduce((acc, key) => {
      const traditionalFee = traditionalFees[key]
      const portfolioFee = portfolioFees[key]

      // Filter out fees that are not visible (both are undefined)
      if (traditionalFee === undefined && portfolioFee === undefined) return acc

      acc[key] = {
        traditional: traditionalFees[key],
        portfolio: portfolioFees[key]
      }

      return acc
    }, {})
}

/**
 * Returns the issuance fees
 *
 * @param financialInstrument - The type of financial instrument
 * @param issuanceType - The type of issuance, either new or migration
 * @param amount - The capitalization amount of the issuance
 */
export function useGetIssuanceFees(financialInstrument: FinancialInstrument, issuanceType: IssuanceType, amount: number): IssuanceFees {
  const traditionalFirstYear = getIssuanceFees(financialInstrument, issuanceType, amount, 'initialFee', 'traditional')
  const traditionalMaintenance = getIssuanceFees(financialInstrument, issuanceType, amount, 'maintenanceFee', 'traditional')
  const portfolioFirstYear = getIssuanceFees(financialInstrument, issuanceType, amount, 'initialFee', 'portfolio')
  const portfolioMaintenance = getIssuanceFees(financialInstrument, issuanceType, amount, 'maintenanceFee', 'portfolio')

  const traditionalTotalFirstYear = useTotalIssuanceFee(traditionalFirstYear)
  const traditionalTotalMaintenance = useTotalIssuanceFee(traditionalMaintenance)
  const portfolioTotalFirstYear = useTotalIssuanceFee(portfolioFirstYear)
  const portfolioTotalMaintenance = useTotalIssuanceFee(portfolioMaintenance)

  const savingMoney = useGetSavingMoney(
    traditionalTotalFirstYear,
    traditionalTotalMaintenance,
    portfolioTotalFirstYear,
    portfolioTotalMaintenance
  )

  const savingPercent = getSavingPercent(
    traditionalTotalFirstYear,
    traditionalTotalMaintenance,
    portfolioTotalFirstYear,
    portfolioTotalMaintenance
  )

  return {
    initialFee: {
      fees: groupFees(traditionalFirstYear, portfolioFirstYear),
      total: {
        traditional: traditionalTotalFirstYear,
        portfolio: portfolioTotalFirstYear
      }
    },
    maintenanceFee: {
      fees: groupFees(traditionalMaintenance, portfolioMaintenance),
      total: {
        traditional: traditionalTotalMaintenance,
        portfolio: portfolioTotalMaintenance
      }
    },
    savingMoney,
    savingPercent
  }
}
